<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="monitor_wrap">
      <div class="monitor_head">
        <h2>포장수량</h2>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <span
          >조회시간 :
          {{
            new Date(
              plc_sensor_logs.find(x => x.plc_sensor_id == 18).create_time,
            ) | format년월일요일시분초
          }}</span
        >
      </div>
      <div class="monitor_body">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <div class="monitor_card">
          <h6>KF94</h6>
          <p>
            {{
              this.$makeComma(
                Math.round(
                  this.$decimalMul(
                    plc_sensor_logs.find(x => x.plc_sensor_id == 18).value,
                    0.98,
                  ),
                ),
              )
            }}
            <span>EA</span>
          </p>
          <div>
            <div>
              일 포장수량
              <p>
                {{
                  this.$makeComma(
                    Math.round(
                      this.$decimalMul(
                        plc_sensor_logs.find(x => x.plc_sensor_id == 18).value,
                        0.98,
                      ),
                    ),
                  )
                }}
                <span>EA</span>
              </p>
            </div>
            <div>
              월 포장수량
              <p>
                {{
                  this.$makeComma(
                    Math.round(this.$decimalMul(thisMonthCounterLog, 0.98)),
                  )
                }}
                <span>EA</span>
              </p>
            </div>
          </div>
        </div>
        <div class="monitor_card">
          <h6>월 포장수량</h6>
          <line-chart
            class="chart_wrap"
            :width="$screen.width > 1280 ? 700 : 480"
            :height="$screen.width > 1280 ? 300 : 280"
            :data="chartData.data"
            :options="chartData.options"
            :key="key"
            >그래프</line-chart
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import LineChart from '@/layouts/components/charts/LineChart2';
import {
  yyyymmdd,
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';

export default {
  mixins: [fetchMixin],
  components: { LineChart },
  data() {
    return {
      fullScreen: false,
      end_date: yyyymmdd(new Date()),
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_counter_log: 'getCounterLogDay', // 생산수량,푸장수량
      plc_sensor_logs: 'getPlcSensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    todayCounterLog() {
      let today = yyyymmdd(new Date());
      let todayClone = this.lodash
        .clonedeep(this.plc_counter_log)
        .filter(x => x.end_time.substr(0, 10) == today)
        .map(x => x.value);
      const result = todayClone.reduce((sum, currValue) => {
        return this.$decimalAdd(sum, currValue);
      }, 0);
      return result;
    },
    thisMonthCounterLog() {
      let thisMonth = yyyymmdd(new Date());
      let thisMonthClone = this.lodash
        .clonedeep(this.plc_counter_log)
        .sort((a, b) => a.end_time - b.end_time)
        .filter(x => x.end_time.substr(5, 2) == thisMonth.substr(5, 2))
        .map(x => x.value);
      const result = thisMonthClone.reduce((sum, currValue) => {
        return this.$decimalAdd(sum, currValue);
      }, 0);
      return result;
    },
    chartMonth() {
      let group_month = this.lodash
        .clonedeep(this.plc_counter_log)
        .sort((a, b) => a.end_time - b.end_time)
        .map(x => {
          x.month = x.end_time.substr(0, 7);
          return x;
        });

      const obj = group_month.reduce(function(rv, x) {
        (rv[x['month']] = rv[x['month']] || []).push(x);
        return rv;
      }, {});

      let arr = [];
      for (let k in obj) {
        arr.push({
          month: k,
          element: obj[k]
            .map(x => x.value)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
        });
      }
      return arr;
    },
    chartData() {
      let month = new Date();
      month.setMonth(month.getMonth() - 5);
      let year = month.getFullYear();
      let labels = [];
      let thisMonth = month.getMonth() + 1;
      for (let i = 0; i < 7; i++) {
        if (thisMonth == 13) {
          thisMonth = 1;
          year += 1;
        } else if (thisMonth == -1) {
          thisMonth = 12;
          year -= 1;
        }
        if (i == 6) {
          year = '';
          thisMonth = '';
        }
        labels.push({
          this_month:
            thisMonth > 9
              ? `${year}-${thisMonth}`.substr(2, 6)
              : `${year}-0${thisMonth}`.substr(2, 6),
          month_index: thisMonth > 9 ? `${thisMonth}` : `0${thisMonth}`,
          month_counter: 0,
        });
        thisMonth++;
      }
      labels.forEach(el => {
        const elementTemp = this.chartMonth.find(
          x => el.month_index == x.month.substr(5, 7),
        );
        if (elementTemp != undefined) {
          el.month_counter = Math.round(
            this.$decimalMul(elementTemp.element, 0.98),
          );
        }
      });
      let monthSum = [];
      let labelsMap = labels.map(x => x.month_counter);
      labelsMap.reduce((acc, cur, index) => {
        return (monthSum[index] = this.$decimalAdd(acc, cur));
      }, 0);
      const data = {
        labels: labels.map(x => x.this_month),
        datasets: [
          {
            label: '누적 포장수량',
            data: monthSum.slice(0, labelsMap.length - 1),
            borderColor: '#272f68',
            backgroundColor: '#272f68',
            borderWidth: 2,
            fill: false,
            bezierCurve: false,
            stack: 'combined',
            pointRadius: [3, 3, 3, 3, 3, 5, 3],
          },
          {
            label: '월 포장수량',
            data: labelsMap.slice(0, labelsMap.length - 1),
            backgroundColor: '#ff1e1e',
            borderWidth: 0,
            stack: 'combined',
            barPercentage: [0.2, 0.2, 0.2, 0.2, 0.2, 0.5, 0.2],
            type: 'bar',
          },
        ],
      };
      let options = {
        legend: {
          display: true,
          labels: {
            boxWidth: 12,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: value => {
                  return this.$makeComma(value);
                },
              },
            },
          ],
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
          line: {
            tension: 0.1,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 78,
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: false,
        },
      };
      return { data: data, options: options };
    },
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  methods: {
    async fetch_plc_sensor_log_now() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_LOG_NOW')
        .then(response => {
          this.$store.commit(
            'setPlcSensorLogTemp',
            this.lodash.clonedeep(response.data.data_list),
          );
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            `온습도 정보를 불러오는 중 오류가 발생하였습니다.`,
          );
        })
        .finally(() => {});
    },
  },
  async created() {
    this.timerCount = this.timerMax;
    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    await this.fetch_plc_sensor_log_now();
    if (this.plc_counter_log.length < 1) {
      let start_date = new Date();
      start_date.setMonth(start_date.getMonth() - 5);
      await this.FETCH_WITH_PAYLOAD('FETCH_COUNTER_LOG_DAY2', {
        start: yyyymmdd(start_date)
          .replace(/(.{9})/g, '$11')
          .substr(0, 10),
        end: this.end_date,
      });
    }

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 2) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
    this.key++;
  },
};
</script>
<style lang="scss" scoped></style>
