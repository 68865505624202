<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="monitor_wrap">
      <div class="monitor_head">
        <h2>불량수량</h2>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <span>조회시간 : {{ searchTimeStr | format년월일요일시분초 }}</span>
      </div>
      <div class="monitor_body">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <div class="monitor_card poor_card">
          <h6>KF94</h6>
          <div>
            <bar-chart
              class="chart_wrap bar"
              :key="keys"
              :data="BarChartData.data"
              :options="BarChartData.options"
            >
            </bar-chart>
            <ul>
              <li>
                생산 <span>{{ $makeComma(getCheckAndUnPassSum.check) }}</span>
              </li>
              <li>
                불량 <span>{{ $makeComma(getCheckAndUnPassSum.unpass) }}</span>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                생산수량
                <p>
                  {{ $makeComma(getCheckAndUnPassSum.check) }} <span>EA</span>
                </p>
              </li>
              <li>
                불량수량
                <p>
                  {{ $makeComma(getCheckAndUnPassSum.unpass) }} <span>EA</span>
                </p>
              </li>
            </ul>
            <div class="doughnut_wrap">
              <p>불량률</p>
              <div class="doughnut">
                <div class="center_txt">
                  <p>
                    {{
                      $makeNumberFixed2(getCheckAndUnPassSum.unpass_percent)
                    }}%
                  </p>
                </div>
                <doughnut-chart
                  :key="keys"
                  :data="DoughnutChartData.data"
                  :options="DoughnutChartData.options"
                  >그래프</doughnut-chart
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetchMixin from '@/mixins/fetch';
import BarChart from '@/layouts/components/charts/ReportBarChart';
import DoughnutChart from '@/layouts/components/charts/DoughnutChart';
import { mapGetters } from 'vuex';
import { yyyymmdd, yyyy년MM월dd일, HH시mm분, X요일 } from '@/utils/func';

export default {
  mixins: [fetchMixin],
  components: {
    BarChart,
    DoughnutChart,
  },
  data() {
    return {
      fullScreen: false,
      keys: 0,
      timeNowStr: null,
      timeNowInterval: null,
      searchTimeStr: null,
      searchDataInterval: null,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      seqReports: 'getPoorFromPoorQuality',
    }),
    filteredSeqReports() {
      if (this.seqReports.length < 1) {
        return [];
      } else {
        return this.seqReports.filter(x => x.process_id == 7);
      }
    },
    getCheckAndUnPassSum() {
      if (this.filteredSeqReports.length < 1) {
        return { check: 0, unpass: 0 };
      } else {
        return {
          check: this.$decimalAdd(
            this.filteredSeqReports
              .map(x => x.pass_quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
            this.filteredSeqReports
              .map(x => x.unpass_quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
          ),
          unpass: this.filteredSeqReports
            .map(x => x.unpass_quantity)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
          unpass_percent: this.$decimalMul(
            this.$decimalDiv(
              this.filteredSeqReports
                .map(x => x.unpass_quantity)
                .reduce((a, b) => this.$decimalAdd(a, b), 0),
              this.$decimalAdd(
                this.filteredSeqReports
                  .map(x => x.pass_quantity)
                  .reduce((a, b) => this.$decimalAdd(a, b), 0),
                this.filteredSeqReports
                  .map(x => x.unpass_quantity)
                  .reduce((a, b) => this.$decimalAdd(a, b), 0),
              ),
            ),
            100,
          ),
        };
      }
    },
    BarChartData() {
      const data = {
        datasets: [
          {
            label: '생산',
            data: [this.getCheckAndUnPassSum.check],
            borderColor: '#cecece',
            backgroundColor: '#cecece',
            borderWidth: 0,
            barPercentage: 0.5,
          },
          {
            label: '불량',
            data: [this.getCheckAndUnPassSum.unpass],
            borderColor: '#ff1e1e',
            backgroundColor: '#ff1e1e',
            borderWidth: 0,
            barPercentage: 0.5,
          },
        ],
      };
      let options = {
        legend: {
          display: true,
          responsive: true,
          position: 'right',
          align: 'start',
          labels: {
            boxWidth: 10,
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: false,
              ticks: {
                // beginAtZero: true,
                max: Math.round(
                  this.$decimalMul(this.getCheckAndUnPassSum.check, 1.2),
                ),
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 78,
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: false,
        },
      };
      return { data: data, options: options };
    },
    DoughnutChartData() {
      let data = {
        datasets: [
          {
            data: [
              this.getCheckAndUnPassSum.unpass_percent,
              100 - this.getCheckAndUnPassSum.unpass_percent,
            ],
            backgroundColor: ['#ff1e1e', '#ddd'],
            lineTension: 0,
          },
        ],
      };
      let options = {
        legend: {
          display: false,
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        cutoutPercentage: 70,
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: false,
        },
      };

      return {
        data: data,
        options: options,
      };
    },
  },
  destroyed() {
    clearInterval(this.timeNowInterval);
    clearInterval(this.searchDataInterval);
  },
  async created() {
    const date = new Date();
    const today = yyyymmdd(date);
    this.timeNowStr = new Date();
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PROCESS_QUALITY_REPORT_TO_POOR_QUALITY',
      { start: today, end: today },
      '불량현황',
    );

    this.searchTimeStr = new Date();
    this.keys += 1;
    this.searchDataInterval = setInterval(async () => {
      const date = new Date();
      const today = yyyymmdd(date);
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_PROCESS_QUALITY_REPORT_TO_POOR_QUALITY',
        { start: today, end: today },
        '불량현황',
      );
      this.searchTimeStr = this.timeNowStr;
      this.keys += 1;
    }, 10000);
    this.timeNowInterval = setInterval(() => {
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
