var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status_board_korea status_board_card",class:{
    th_infomation: _vm.tabIndex == 0,
    sensor_information: _vm.tabIndex == 1,
    production_quantity: _vm.tabIndex == 2,
    package_quantity: _vm.tabIndex == 3,
    poor_quantity: _vm.tabIndex == 4,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v("PLC 모니터링")]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToPlcMonitorPage',"options":[
          { title: '온습도 정보', name: 'th_infomation' },
          { title: '이상 정보', name: 'sensor_infomation' },
          { title: '생산수량', name: 'production_quantity' },
          { title: '포장수량', name: 'package_quantity' },
          { title: '불량수량', name: 'poor_quantity' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:{ active: _vm.tabIndex == 0 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("온습도 정보")])]),_c('li',{class:{ active: _vm.tabIndex == 1 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("이상 정보")])]),_c('li',{class:{ active: _vm.tabIndex == 2 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("생산수량")])]),_c('li',{class:{ active: _vm.tabIndex == 3 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(3)}}},[_vm._v("포장수량")])]),_c('li',{class:{ active: _vm.tabIndex == 4 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(4)}}},[_vm._v("불량수량")])]),_vm._v(" ' ")])]):_vm._e(),(_vm.tabIndex == 0)?_c('plc-th-info-form'):_vm._e(),(_vm.tabIndex == 1)?_c('plc-sensor-info-form'):_vm._e(),(_vm.tabIndex == 2)?_c('production-quantity-form'):_vm._e(),(_vm.tabIndex == 3)?_c('plc-package-quantity-form'):_vm._e(),(_vm.tabIndex == 4)?_c('poor-quantity-form'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }