<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="monitor_wrap">
      <div class="monitor_head">
        <h2>이상 정보</h2>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <span>조회시간 : {{ timeStr }}</span>
      </div>
      <div class="monitor_body">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <div class="monitor_card sensor_card">
          <h6>
            필터/이어밴드
          </h6>
          <p :class="{ normal: !error_state }">
            {{ error_state ? '오류' : '정상' }}
          </p>
          <!-- class="normal" => color green, class 없으면 color red -->
          <div>
            <ul>
              <li>
                <span>D</span>
                <p>{{ $makeComma(countErrorLog.today) }}</p>
              </li>
              <li>
                <span>W</span>
                <p>{{ $makeComma(countErrorLog.week) }}</p>
              </li>
              <li>
                <span>M</span>
                <p>{{ $makeComma(countErrorLog.month) }}</p>
              </li>
              <li>
                <span>Y</span>
                <p>{{ $makeComma(countErrorLog.year) }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="monitor_card sensor_card">
          <h6>
            코편
          </h6>
          <p :class="{ normal: !error_state2 }">
            {{ error_state2 ? '오류' : '정상' }}
          </p>
          <!-- class="normal" => color green, class 없으면 color red -->
          <div>
            <ul>
              <li>
                <span>D</span>
                <p>{{ $makeComma(countErrorLog2.today) }}</p>
              </li>
              <li>
                <span>W</span>
                <p>{{ $makeComma(countErrorLog2.week) }}</p>
              </li>
              <li>
                <span>M</span>
                <p>{{ $makeComma(countErrorLog2.month) }}</p>
              </li>
              <li>
                <span>Y</span>
                <p>{{ $makeComma(countErrorLog2.year) }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일, HH시mm분, X요일 } from '@/utils/func';
import { format년월일요일시분초 } from '@/utils/filters';
export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      refreshInterval: null,
      timeInterval: null,
      nowWeeks: [],
      timeStr: null,

      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      plc_error_logs: 'getPlcErrorLog',
      plc_sensor_logs: 'getPlcSensorLog',
    }),
    error_state() {
      if (this.plc_sensor_logs.length < 1) {
        return false;
      } else {
        const foundSensor = this.plc_sensor_logs.find(
          x => x.plc_sensor_id == 8,
        );
        if (foundSensor == undefined) {
          return false;
        } else {
          return foundSensor.value == 1 ? true : false;
        }
      }
    },
    groupLogByCreateTime() {
      if (this.plc_error_logs.length < 1) {
        return [{ year: 0, month: 0, week: 0, today: 0 }];
      } else {
        const filterSensor = this.lodash
          .clonedeep(this.plc_error_logs)
          .filter(x => x.plc_sensor_id == 8);
        const today = yyyymmdd(new Date());

        filterSensor.map(x => {
          const elTime = yyyymmdd(new Date(x.create_time));
          const elYear = elTime.substr(0, 4);
          const elMonth = elTime.substr(0, 7);
          const thisYear = today.substr(0, 4);
          const thisMonth = today.substr(0, 7);
          x.elTime = elTime;
          x.elYear = elYear;
          x.elMonth = elMonth;
          x.thisYear = thisYear;
          x.thisMonth = thisMonth;

          x.year = 0;
          x.month = 0;
          x.week = 0;
          x.today = 0;
          if (thisYear == elYear) {
            x.year++;
          }
          if (thisMonth == elMonth) {
            x.month++;
          }
          if (this.nowWeeks.includes(elTime)) {
            x.week++;
          }
          if (today == elTime) {
            x.today++;
          }
          return x;
        });
        return filterSensor;
      }
    },
    countErrorLog() {
      if (this.groupLogByCreateTime.length < 1) {
        return { year: 0, month: 0, week: 0, today: 0 };
      } else {
        return {
          year: this.groupLogByCreateTime
            .map(x => x.year)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
          month: this.groupLogByCreateTime
            .map(x => x.month)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
          week: this.groupLogByCreateTime
            .map(x => x.week)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
          today: this.groupLogByCreateTime
            .map(x => x.today)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
        };
      }
    },

    error_state2() {
      if (this.plc_sensor_logs.length < 1) {
        return false;
      } else {
        const foundSensor = this.plc_sensor_logs.find(
          x => x.plc_sensor_id == 0,
        );
        if (foundSensor == undefined) {
          return false;
        } else {
          return foundSensor.value == 1 ? true : false;
        }
      }
    },
    groupLogByCreateTime2() {
      if (this.plc_error_logs.length < 1) {
        return [{ year: 0, month: 0, week: 0, today: 0 }];
      } else {
        const filterSensor = this.lodash
          .clonedeep(this.plc_error_logs)
          .filter(x => x.plc_sensor_id == 0);
        const today = yyyymmdd(new Date());

        filterSensor.map(x => {
          const elTime = yyyymmdd(new Date(x.create_time));
          const elYear = elTime.substr(0, 4);
          const elMonth = elTime.substr(0, 7);
          const thisYear = today.substr(0, 4);
          const thisMonth = today.substr(0, 7);
          x.elTime = elTime;
          x.elYear = elYear;
          x.elMonth = elMonth;
          x.thisYear = thisYear;
          x.thisMonth = thisMonth;

          x.year = 0;
          x.month = 0;
          x.week = 0;
          x.today = 0;
          if (thisYear == elYear) {
            x.year++;
          }
          if (thisMonth == elMonth) {
            x.month++;
          }
          if (this.nowWeeks.includes(elTime)) {
            x.week++;
          }
          if (today == elTime) {
            x.today++;
          }
          return x;
        });
        return filterSensor;
      }
    },
    countErrorLog2() {
      if (this.groupLogByCreateTime2.length < 1) {
        return { year: 0, month: 0, week: 0, today: 0 };
      } else {
        return {
          year: this.groupLogByCreateTime2
            .map(x => x.year)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
          month: this.groupLogByCreateTime2
            .map(x => x.month)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
          week: this.groupLogByCreateTime2
            .map(x => x.week)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
          today: this.groupLogByCreateTime2
            .map(x => x.today)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
        };
      }
    },
  },
  destroyed() {
    clearInterval(this.refreshInterval);
    clearInterval(this.timeInterval);
  },
  async created() {
    if (this.nowWeeks.length < 1) {
      var currentDay = new Date();
      var theYear = currentDay.getFullYear();
      var theMonth = currentDay.getMonth();
      var theDate = currentDay.getDate();
      var theDayOfWeek = currentDay.getDay();

      var thisWeek = [];

      for (var i = 0; i < 7; i++) {
        var resultDay = new Date(
          theYear,
          theMonth,
          theDate + (i - theDayOfWeek),
        );
        var yyyy = resultDay.getFullYear();
        var mm = Number(resultDay.getMonth()) + 1;
        var dd = resultDay.getDate();

        mm = String(mm).length === 1 ? '0' + mm : mm;
        dd = String(dd).length === 1 ? '0' + dd : dd;

        thisWeek[i] = yyyy + '-' + mm + '-' + dd;
      }
      this.nowWeeks = thisWeek;
    }
    this.timeNowStr = format년월일요일시분초(new Date());

    this.timeStr = this.timeNowStr;
    await this.FETCH_NO_SPINNER('FETCH_PLC_ERROR_LOG', '에러 로그');
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '에러 상태');

    this.refreshInterval = setInterval(async () => {
      this.timeStr = this.timeNowStr;
      await this.FETCH_NO_SPINNER('FETCH_PLC_ERROR_LOG', '에러 로그');
      await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '에러 상태');
    }, 10000);
    this.timeInterval = setInterval(() => {
      // this.timeNowStr = format년월일요일시분초(new Date());
      const newDate = new Date();
      this.curr_date = yyyy년MM월dd일(newDate);
      this.curr_time = HH시mm분(newDate);
      this.curr_weekDay = X요일(newDate);
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
