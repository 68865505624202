<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="monitor_wrap">
      <div class="monitor_head">
        <h2>온습도 정보</h2>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <span v-if="grouped_temphumi_logs.length > 0"
          >조회시간 :
          {{
            new Date(grouped_temphumi_logs[0].create_time)
              | format년월일요일시분초
          }}</span
        >
      </div>
      <div class="monitor_body">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <div
          class="monitor_card th_card"
          v-for="item in grouped_temphumi_logs"
          :key="item.id"
        >
          <h6>
            {{ `${item.plc_location_name}: ${item.plc_sensor_type_name}` }}
          </h6>
          <p class="temp">{{ item.value.toFixed(1) }}<span> ℃</span></p>
          <p class="humi">{{ item.humi_value.toFixed(1) }}<span> %</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import {
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: yyyy년MM월dd일HH시mm분ss초(new Date()),
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_tempHumi_logs: 'getTemperatureHumiditySensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    grouped_temphumi_logs() {
      let clone = this.lodash.clonedeep(this.plc_tempHumi_logs);
      clone = clone.sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);

      let lists = [];
      let location_id_temp = null;
      let duplicate_count = 2;
      clone.forEach((element, index) => {
        if (index % 2 == 0) {
          if (element.plc_location_id == location_id_temp) {
            element.plc_sensor_type_name =
              element.plc_sensor_type_name + duplicate_count;
            duplicate_count++;
          } else {
            duplicate_count = 2;
          }
          location_id_temp = element.plc_location_id;
          element.humi_value = clone[index + 1].value;
          lists.push(element);
        }
      });
      return lists;
    },
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      const newDate = new Date();
      this.curr_date = yyyy년MM월dd일(newDate);
      this.curr_time = HH시mm분(newDate);
      this.curr_weekDay = X요일(newDate);
    }, 10000);
  },
};
</script>

<style lang="scss" scoped></style>
