<template>
  <div
    id="contents"
    class="status_board_korea status_board_card"
    :class="{
      th_infomation: tabIndex == 0,
      sensor_information: tabIndex == 1,
      production_quantity: tabIndex == 2,
      package_quantity: tabIndex == 3,
      poor_quantity: tabIndex == 4,
    }"
  >
    <div class="contents_head">
      <h2>PLC 모니터링</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '온습도 정보', name: 'th_infomation' },
            { title: '이상 정보', name: 'sensor_infomation' },
            { title: '생산수량', name: 'production_quantity' },
            { title: '포장수량', name: 'package_quantity' },
            { title: '불량수량', name: 'poor_quantity' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">온습도 정보</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">이상 정보</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">생산수량</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">포장수량</a>
          </li>
          <li :class="{ active: tabIndex == 4 }">
            <a @click="SetOpenTabIndex(4)">불량수량</a>
          </li>
          '
        </ul>
      </div>
      <plc-th-info-form v-if="tabIndex == 0"></plc-th-info-form>
      <plc-sensor-info-form v-if="tabIndex == 1"></plc-sensor-info-form>
      <production-quantity-form v-if="tabIndex == 2"></production-quantity-form>
      <plc-package-quantity-form
        v-if="tabIndex == 3"
      ></plc-package-quantity-form>
      <poor-quantity-form v-if="tabIndex == 4"></poor-quantity-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import PlcPackageQuantityForm from '@/views/forms/Custom/Monitor/10/PlcPackageQuantityForm';
import ProductionQuantityForm from '@/views/forms/Custom/Monitor/10/ProductionQuantityForm';
import PlcThInfoForm from '@/views/forms/Custom/Monitor/10/PlcThInfoForm';
import PlcSensorInfoForm from '@/views/forms/Custom/Monitor/10/PlcSensorInfoForm';
import PoorQuantityForm from '@/views/forms/Custom/Monitor/10/PoorQuantityForm';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    ProductionQuantityForm,
    PlcPackageQuantityForm,
    PlcThInfoForm,
    PlcSensorInfoForm,
    PoorQuantityForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'koreadoctor_monitoring';
</style>
